<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '30%'"
  >
    <v-card>
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
        >{{ $t("select", { name: $tc("customer", 2) }) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="asignate-form"
        @submit.prevent="validateForm('asignate-form')"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col class="py-0" cols="8">
              <label v-text="$tc('customer')"></label>
              <v-autocomplete
                outlined
                hide-details
                dense
                v-model="customer"
                :items="customers"
                item-value="id"
                :search-input.sync="searchCustomer"
                :loading="isLoadingCustomer"
                cache-items
                item-text="user.fullname"
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('asignate-form.customer')"
                data-vv-name="customer"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn
            outlined
            @click="
              dialog = false;
              customer = appointment.customer_id;
            "
            style="height: 25px"
            elevation="0"
            class="mr-3"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
            :disabled="customer === pre_customer"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: "" }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CustomerACard",
  props: ["appointment"],
  watch: {
    searchCustomer(val) {
      //console.log(val);
      this.fetchCustomers();
    },
  },
  data() {
    return {
      isLoadingCustomer: false,
      searchCustomer: "",
      dialog: false,
      customers: [],
      customer: null,
      pre_customer: null,
      search: null,
    };
  },
  mounted() {},
  methods: {
    ...mapActions("customers", ["getCustomers"]),
    setCustomer(customer_id) {
      this.customer = customer_id;
      this.pre_customer = customer_id;
      this.fetchCustomers();
      this.dialog = true;
    },
    fetchCustomers() {
      //if (this.isLoadingCustomer) return;
      this.isLoadingCustomer = true;
      let filters = {
        itemsPerPage: 20,
        search: this.searchCustomer,
      };
      if (this.$store.getters["auth/isStudio"])
        filters.studio_id = this.$store.state.auth.user.studio.id;
      if (this.$store.getters["auth/isTattooer"])
        filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
      this.getCustomers({
        filters,
        pagination: {},
      }).then((response) => {
        let cs = response.data.filter((c) => {
          return !this.appointment.extra_customers
            .map((ec) => ec.id)
            .includes(c.id);
        });
        this.customers = cs;
        this.isLoadingCustomer = false;
      });
    },
    ...mapActions("appointments", ["addAppointmensCustomer"]),

    async validateForm(scope) {
      let result = await this.$validator.validateAll(scope);
      if (result) {
        console.log("save");
        this.addAppointmensCustomer({
          appointment_id: this.appointment.id,
          customer_id: this.customer,
          pre_customer_id: this.pre_customer,
        }).then((response) => {
          if (response.success) {
            this.$alert("Cliente asignado correctamente");
            this.dialog = false;
            this.$emit("update");
          } else {
            this.$alert(response.error);
          }
        });
      }
    },
  },
};
</script>



<style scoped lang="sass">
label
  //line-height: 1
  height: 25px
  display: block
.v-data-table
  td
    padding: 3px 8px !important
    &:first-child
      color: var(--v-gris1-base)
      min-width: 200px
      width: 200px
    &:last-child
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 200px
h2
  font-weight: normal
  font-size: 20px
</style>